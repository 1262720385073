* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
  height: 100%;
  width: 100%;
  user-select: none;
  font-family: 'Montserrat', sans-serif;
  position: fixed;
  overflow: hidden;
  }

code {
  font-family: 'Montserrat', sans-serif;
}

#root {
  font-family: 'Montserrat', sans-serif;
}